import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { BgImage } from "gbimage-bridge"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import SgHeadings from "../components/sg-headings"
import Cta from "../components/call_to_action"

// Service page data from collection

export default function ServiceDetails( {data} ) {

// declare variables

  const sv = data.strapiService  
  const headline = sv.headline
  const heroText = sv.heroText
  const seo = {
    metaTitle: headline,
    metaDescription: heroText,
  }
  const heroImage = getImage(sv.heroImage.localFile)
  const paraOne = sv.paragraphOne.data.paragraphOne
  const paraTwo = sv.paragraphTwo.data.paragraphTwo
  const paraThree = sv.paragraphThree.data.paragraphThree
  const imageOne = getImage(sv.imageOne.localFile)
  const imageTwo = getImage(sv.imageTwo.localFile)
  const imageThree = getImage(sv.imageThree.localFile)

  // Return the page

  return(
    <Layout>
      <Seo seo={seo} />
      <div className="relative w-full h-full bg-gradient-to-r from-black to-emerald-600">
            <BgImage 
              image={heroImage} 
              alt={heroText}
              className="absolute h-96 bg-center object-cover mix-blend-overlay" />
                <div className="container absolute top-0 left-1/2 -translate-x-1/2">
                  <SgHeadings title={headline} description={heroText} />
                </div>  
          </div>
          <div className="container pt-6 pb-6 m-auto px-6 text-gray-600 md:px-12 xl:px-6">
            <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                    <div className="md:w-6/12">
                    <GatsbyImage className="rounded-3xl" image={imageOne} alt={headline} />
                    </div>
                    <div className="md:w-6/12">
                       <div className="p-2 space-y-4 prose"><ReactMarkdown>{paraOne}</ReactMarkdown></div>
                  </div>
            </div>
          </div>
          <div className="container pt-6 pb-6 m-auto px-6 text-gray-600 md:px-12 xl:px-6">
            <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                    <div className="md:w-6/12">
                       <div className="p-2 space-y-4 prose"><ReactMarkdown>{paraTwo}</ReactMarkdown></div>
                  </div>
                  <div className="invisible md:visible w-6/12">
                    <GatsbyImage className="rounded-3xl" image={imageTwo} alt={headline} />
                    </div>
            </div>
          </div>
          <div className="container pt-6 pb-6 m-auto px-6 text-gray-600 md:px-12 xl:px-6">
            <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                    <div className="md:w-6/12">
                    <GatsbyImage className="rounded-3xl" image={imageThree} alt={headline} />
                    </div>
                    <div className="md:w-6/12">
                       <div className="p-2 space-y-4 prose"><ReactMarkdown>{paraThree}</ReactMarkdown></div>
                  </div>
            </div>
          </div>
      <Cta />
    </Layout>
  )
}

// Get the data from strapi single product data related to slug
export const query = graphql`
query ($slug: String) {
  strapiService (slug: {eq: $slug}) {
    headline
    heroText
    heroImage {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    slug
    paragraphOne {
      data {
        paragraphOne
      }
    }
    paragraphTwo {
      data {
        paragraphTwo
      }
    }
    paragraphThree {
      data {
        paragraphThree
      }
    }
    imageOne {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    imageTwo {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    imageThree {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
}
`
