import React from "react"
import ProductSheet from "../images/toristy_product_sheet.png"


// A simple form which re-directs to download_thanks after submitting the form.
// emails sent to info@toristy.com, slack and hubspot.
const Cta = () => {      
        
  return (      
                <div className="container grid md:grid-cols-3 relative w-3/4 p-8 bg-brandgreen rounded-t-2xl">
                    <figure>
                    <div className="md:pl-24"><img src={ProductSheet} alt="Toristy Product Sheet" className="w-48" /></div>
                    </figure>
                    <figure className="pt-2">
                    <div className="text-xl text-emerald-500">Toristy backStack</div>
                    <div className="pt-4 pr-4 text-white">The backStack is a set of software services designed to sit in the background so that your brand is front and centre. This white paper outlines the 
                    software services we offer to enable companies to easily manage and maintain experiences on their own websites and marketplaces. Fill in the form to get it today.</div>
                    </figure>
                    <figure>
                    <div className="pl-2">
                    <script src="https://js.hcaptcha.com/1/api.js" async defer></script>
                        <span className="text-xs text-emerald-500">* Required field</span>
                        <form method="post" action="https://usebasin.com/f/b8b02f1eaf3e">
                            <input type="hidden" name="toristy.com" value="Download product sheet"></input>
                            <div className="space-x-4 pb-2">
                            <label htmlFor="firstName" className="text-white">First Name* <input className="rounded-lg w-full text-black" type="text" name="firstName" /></label>
                            </div>
                            <div className="space-x-4 pb-2">
                            <label htmlFor="lastName" className="text-white">Last Name* <input className="rounded-lg w-full text-black" type="text" name="lastName" /></label>
                            </div>
                            <div className="col-span-1 pb-2">
                            <label htmlFor="email" className="text-white">Email* <input className="rounded-lg w-full text-black" type="email" name="email" />
                            </label>
                            </div>
                            <div className="h-captcha" data-sitekey="7fe715a1-151f-4c63-b497-bd971974df05"></div>
                            <div className="col-span-1 pb-2">
                            <button className="bg-brandblue text-center text-white py-2 px-4 rounded-lg focus:ring focus:ring-blue-300 hover:bg-emerald-500" 
                            type="submit">Get the download</button>
                            </div>
                        </form>
                    </div>
                    </figure>
                </div>

    )
}
export default Cta